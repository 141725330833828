.about{
    background: #f6f9fc;
    padding-top: 120px;
    padding-bottom: 100px;
}

.about .container{
    max-width: 1200px; 
    margin: 0 auto;
    padding: 10px;
}

.about-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.about-content{
    flex: 0 0 50%;
    max-width: 50%;
}

.about-img{
    flex: 0 0 41%;
    max-width: 41%;
}

.about-img img{
    max-width: 100%;
    box-shadow: 0 20px 30px rgb(35 47 85 / 10%);
}


.about-content_title h2{
    font-size: 36px;        
}

.about-content_title{
    margin-bottom: 50px;
}

/* .about-offers a{
    margin-top: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 18xp;
    font-weight: 700;
    border-radius: 25px;
    background: linear-gradient(to bottom,#ff4495 0%,#ff6d6d 100%);
    padding: 17px 27px;
} */

.about-ofers-content h3{

}

.about-ofers-content p{

}

.about-ofers-item{
    margin-bottom: 20px;
}

@media screen and (max-width: 730px) {
    .about-wrapper{
        flex-direction: column;
    }
    .about-img{
        flex: 0 0 50%;
        padding-bottom: 20px;
    }
    .about-content{
        text-align: center;
    }
}
