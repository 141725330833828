.categories{
padding: 120px 0;
}

.categories .container{
    max-width: 1200px; 
    margin: 0 auto;
    padding: 100px 10px 0 10px;
    
}

.categories .categories-about{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.categories .categories-about span{
    color: #ff4495;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 19px;
    display: block;
    text-transform: capitalize;
}

.categories .categories-about h2{
    color: #232f55;
    font-size: 36px;
    display: block;
    font-weight: 600;
    margin-bottom: 17px;
}

.categories .categories-about .categories-wrapper{
    text-align: center;
    flex: 0 0 50%;
    width: 50%;
}

.categories-row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.categories-block{
    margin: 15px;
    flex: 0 0 30%;
    padding: 10px;
}

.categories-block-img{
    margin-bottom: 50px;
    text-align: center;
}

.categories-content{
    text-align: center;
    padding-bottom: 40px;
}

.categories-content h5{
    color: #232f55;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 21px;
}

.categories-content p{
    margin-bottom: 36px;
    color: #3f4854;
    font-size: 16px;
    line-height: 30px;
}

@media screen and (max-width: 750px){
    .categories .categories-about .categories-wrapper{
        width: 66%;
    }
}