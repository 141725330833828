.stuff{
    padding-top: 150px;
    padding-bottom: 100px;
}
.stuff .container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}
.stuff-title{
    text-align: center;
    margin-bottom: 55px;
}

.stuff-title h2{
    color: #232f55;
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 17px;
}
.stuff-blocks{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.stuff-item{
    flex: 0 0 33%;
    max-width: 33%;
    padding: 0 15px;
}
.stuff-item-wrapper{
    margin-bottom: 30px;
}
.stuff-img{
    position: relative;
}
.stuff-img img{
    width: 100%;
    border-radius: 15px;
}

.img-detaled{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.stuff-img::before{
    border-radius: 15px;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    content: "";
    background: rgba(0,9,94,.4);
    z-index: 1;
}

.stuff-img:hover{
    background: rgba(255, 255, 255, 0.021);
}
.stuff-img img:hover{
    transform: scale(1.1);
}
.stuff-item-wrapper h3{
    color: #232f55;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 30px;
}
.img-detaled{
    z-index: 2;
}
.img-detaled h4{
    color: #fff;
    font-size: 27px;
    font-weight: 700;
    display: inline-block;
    text-transform: uppercase;
    margin-top: 8px;
}
.img-detaled p{
    color: #fff;
    font-size: 8px;
    font-weight: normal;
    text-transform: uppercase;
}
.img-detaled span{
    width: 23px;
    height: 23px;
    background: #fff;
    color: #fff;
    border-radius: 30px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

@media screen and (max-width: 650px) {
    .stuff-item{
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
}