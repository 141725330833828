.count{
    padding-top: 150px;
    padding-bottom: 90px;
    border-bottom: 1px solid #dee2e6;
}

.count .container{
    max-width: 1200px;
    margin: 0 auto;
}

.count-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.count-title-section{
    flex: 0 0 75%;
    padding: 0 15px;
    max-width: 75%;
}

.count-title-content{
    margin-bottom: 60px;
}

.count-title{
    text-align: center;
}

.count-title h2{
    color: #232f55;
    font-size: 46px;
    display: block;
    font-weight: 600;
    margin-bottom: 17px;
}

.count-title p{
    color: #232f55;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 55px;
    font-weight: normal;
}

.count-title a{
    text-decoration: none;
    border: 1px solid #ff4495;
    color: #ff4495;
    background: none;
    text-transform: normal;
    padding: 13px 34px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    transition: color .4s linear;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    z-index: 1;
}

.count-counter{
    display: flex;
    justify-content: space-between;
    
}

.coun-item{
    flex: 0 0 33%;
    max-width: 33%;
}

.count-item-wrapper{
    margin-bottom: 30px;
    text-align: center;
}

.counter-number {
    margin-bottom: 10px;
}
.counter-number span{
    color: #132e43;
    font-size: 64px;
    font-weight: 600;
    margin-bottom: 12px;
    display: inline-block;
    text-transform: uppercase;
}

.counter-text p{
    color: #132e43;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 15px;
}

@media screen and (max-width: 650px){
    .count-counter{
        display: block;
    }
}