.header{
    width: 100%;
    height: auto;
    background: linear-gradient(to right,#00095e 0%,#0c0c1f 100%);
    position: fixed;
    z-index: 4;
}

.header a{
    text-decoration: none;
    color: #fff;
}

.header .container{
    max-width: 1200px;
    margin: 0 auto;
}

.header-left{
    display: flex;
}


.header-menu-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.header-left ul{
    list-style: none;
}

.header-links{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.header-links li{
    padding: 10px;
    margin-left: 20px;
    color: #fff;
    transition: 2s ease;
    cursor: pointer;
}

.header-links li a{
    font-size: 15px;
    font-weight: 600;
    
}

.header-links li:hover{
    border-bottom: 2px solid #fff;
    animation: border 5s ease;
}

.header-rigth a{
    padding: 12px 21px;
    border: 2px solid #fff;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    border-radius: 25px;
}
.burger-menu{
    display: none;
}

.burger-menu-item{
    display: none;
}

@media screen and (max-width: 915px){
    .header-left .header-links{
        display: none;
    }
    .header-rigth{
        display: none;
    }
    .burger-menu{
        display: block;
        font-size: 25px;
        color: #fff;
        cursor: pointer;
        z-index: 4;
    }

    .burger-menu-item{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: #000;
        transform: translateX(-100%);
        transition: 2s ease;
        
    }

    .burger-menu-item.active{
        transform: translateX(0);
    }

    .burger-menu-item ul{
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100%;
        flex-direction: column;
    }

    .burger-menu-item ul li{
        padding: 20px;
        font-size: 30px;
    }
}