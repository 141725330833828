.main{
    background: linear-gradient(to right,#00095e 0%,#0c0c1f 100%);
}

.main .container{
    max-width: 1200px;
    height: 700px;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    padding: 10px;
    align-items: center;
}

.main-content{
    flex: 0 0 40%;
    z-index: 1;
    color: #fff;
}

.main-content h1{
    font-size: 40px;
    margin-bottom: 10px;
}

.main-content p {
    font-size: 18;
    margin-bottom: 30px;
}

.main-img img{
    width: 100%;
    height: 345px;
    top: 69px;
    left: 229px;
    animation: scale 5s forwards ease-in-out;
}
@keyframes scale {
    from{
        transform: scale(1);
    }

    to{
        transform: scale(2);
    }
}
.main-content a{
    color: #fff;
    text-decoration: none;
    font-size: 18xp;
    font-weight: 700;
    border-radius: 25px;
    background: linear-gradient(to bottom,#ff4495 0%,#ff6d6d 100%);
    padding: 17px 27px;
}

@media screen and (max-width: 995px) {
    .main .container{
        height: 550px;
        max-width: 920px;
    }
}

@media screen and (max-width: 772px){
    .container{
        flex-direction: column-reverse;
    }
    .main-content{
        text-align: center;
    }
    .main-img img{
        height: 255px;
    }
}

@media screen and (max-width: 540px){
    .main .container {
        height: 100vh;
    }
}

