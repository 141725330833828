.services{
    background: linear-gradient(to right,#0c0c1f 0%,#00095e 100%);
    padding-top: 130px;
    padding-bottom: 90px;
}

.services .container{
    max-width: 950px;
    margin: 0 auto;
}

.services .services-content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #fff;
    padding-bottom: 20px;
}

.services .services-title{
    flex: 0 0 41%;
    max-width: 41%;
    padding: 0 10px;
}

.services .services-title h2{
    margin-bottom: 20px;
}
.services .services-text{
    flex: 0 0 41%;
    max-width: 41%;
    padding: 0 10px;
}
.services .services-text span{
    color: #ff4495;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 19px;
    display: block;
    text-transform: capitalize;
}

.services .services-text h2{
    font-size: 36px;
    
}

.services .services-text p{
    font-size: 18px;
}


.services-blocks{
    display: flex;
    justify-content: space-between;

}

.services-item{
    flex: 25%;
    max-width: 25%;
    padding: 0 15px;
    text-align: center;
    color: #fff;
}

.services-item-wrapper{
    box-shadow: 0 10px 16px rgb(84 0 0 / 6%);
    padding: 50px 36px 39px 36px;
    
}

.services-item img{
    margin-bottom: 30px;
}

.services-item .services-item-text{
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 600;
}

.services-subtitle{
    padding-top: 35px;
    text-align: center;
    color: #fff;
}
.services-subtitle p{
    margin-bottom: 15px;
    font-size: 18px;
}
.services-subtitle a{
    color: #fff;
}

@media screen and (max-width: 915px) {
    .categories-row{
        display: block;
    }
}

@media screen and (max-width: 730px){
    .services{
        padding-top: 50px;
    }

    .services-blocks{
        flex-direction: column;
    }

    .services-item{
        flex:  0 0 100%;
        max-width: 100%;
    }
   .services .services-title{
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
    .services .services-text{
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
    }
}