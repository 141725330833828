.footer{
    background: linear-gradient(to right,#00095e 0%,#0c0c1f 100%);
}

.footer .footer-padding{
    padding-top: 100px;
    padding-bottom: 50px;
}

.footer .container{
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.footer .footer-blocks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

.footer-blocks ul{
    list-style: none;
}

.footer-blocks .footer-item{
    flex: 0 0 25%;
    max-width: 25%;
    padding: 10px;
    text-align: center;
}

.footer-item .footer-logo{
    margin-bottom: 35px;
}

.footer-item .footer-title p{
    color: #fff;
    font-size: 16px;
    margin-bottom: 19px;
}

.footer-item .footer-subtitle ul{
    margin-bottom: 20px;
}

.footer-item .footer-subtitle .number, .email {
    font-size: 24px!important;
    color: #fff!important;
    margin-bottom: 12px;
}

.footer-item .footer-title{
    color: #fff;
    font-size: 20px;
  
    font-weight: 400;
}
.footer-item .footer-title h4{
    margin-bottom: 30px;
}
.footer-item .footer-title ul li{
    margin-bottom: 15px;
}

.footer-item .footer-title ul li a{
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline transparent;
}

.foter-form input{
        padding: 5px 20px;
        border-radius: 25px;
        border: 3px solid #0044ff;
}



@media screen and (max-width: 970px) {
    .footer-blocks .footer-item{
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 90px;
    }
}

@media screen and (max-width: 530px) {
    .footer-blocks .footer-item{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 90px;
    }

    .footer-blocks .footer-item:last-child{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 0;
    }
}